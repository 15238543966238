import { CarWithCompany, CostsT } from "@/types";
import { getParamsList } from "@/utils/getters";
import classNames from "classnames";
import { get } from "lodash";
import useTranslation from "next-translate/useTranslation";
import Link from "next/link";
import { ParamsColumnIcon, PriceIntro } from ".";
import { Button } from "../Form";
import styles from "./styles/CarCardSmall.module.scss";

type Props = {
    car: CarWithCompany,
    option?: "vertical" | "horizontal",
    costs?: CostsT
}

const CarCardSmall = ({
    car,
    costs,
    option = "horizontal"
}: Props) => {
    const { t } = useTranslation();

    const {
        photos,
        brand: {
            name: brandName
        } = {},
        model,
        parameters: {
            productionYear,
            ...parameters
        } = {},
        url
    } = car;
    const paramsList = getParamsList(parameters);
    const coverPhotoUrl = photos?.[0]?.url;
    const price = get(costs, "priceList[0].cost");

    return <div className={classNames(styles.carCardSmall, {
        [styles.vertical]: option === "vertical"
    })}>
        {price && <div className={styles.priceWrapper}>
            <PriceIntro
                amount={price}
                option="normal"
            />
        </div>}
        <img
            className={styles.photo}
            src={coverPhotoUrl ? coverPhotoUrl : "/static/cover.jpg"}
            alt="" />

        <div className={styles.body}>
            {
                productionYear && <div className={styles.date}>
                    {productionYear}
                </div>
            }
            <div className={styles.name}>
                {brandName} {model}
            </div>
            <div className={styles.paramsList}>
                {
                    paramsList
                        .slice(0, 4)
                        .map((param, index) => <ParamsColumnIcon
                            param={param}
                            key={index}
                        />)
                }
            </div>
            <div className={styles.controller}>
                <Link href={url}>
                    <Button size="small">{t("common:seeMore")}</Button>
                </Link>
            </div>
        </div>
    </div>;
};




export default CarCardSmall;







