import { CarParametersI } from "@/types";
import { getParamsList } from "@/utils/getters";
import useTranslation from "next-translate/useTranslation";

import styles from "../styles/CarParamsList.module.scss";

type Props = {
    parameters?: Partial<CarParametersI>,
    maxParams?: number
}

const ParameterRow = ({
    paramName,
    value
}: {
    paramName: string,
    value: string | number
}): JSX.Element => {
    const { t } = useTranslation();

    if (!value || paramName === "id") return <></>;

    return <div className={styles.parameterRow}>
        <div>
            {t(`car:params.${paramName}`)}
        </div>
        <div>
            {t(`car:details.${paramName}`,
                { value },
                { fallback: t(`car:details.${paramName}.${value}`) }
            )}
        </div>
    </div>;
};


const CarParamsList = ({
    parameters,
    maxParams
}: Props) => {
    if (!parameters) return <></>;
    const paramsList = getParamsList(parameters);

    return <div>
        {
            (maxParams
                ? paramsList.slice(0, maxParams)
                : paramsList).map(paramObject => {
                const [key, value] = Object.entries(paramObject)[0];

                return <ParameterRow
                    key={key}
                    paramName={key}
                    //todo retun type param type przejrzeć i dostosować!
                    //@ts-ignore
                    value={value}
                />;
            })
        }
    </div>;
};

export default CarParamsList;