import { CarWithCompany, CompanyI, CostsT } from "@/types";
import { getCompanySlug } from "@/utils/getters";
import { get } from "lodash";
import useTranslation from "next-translate/useTranslation";
import Link from "next/link";
import { CarContactCard, CarParamsList, PriceIntro } from ".";
import { Button } from "../Form";
import { SlideFrame } from "../Layout";
import styles from "./styles/CarCard.module.scss";

type Props = {
    car: CarWithCompany,
    costs: CostsT
}

const CarCard = ({
    car,
    costs
}: Props) => {
    const { t } = useTranslation();
    const {
        model,
        url,
        version,
        brand: {
            name: brandName,
        } = {},
        company: {
            coverPhoto,
            name,
        } = {},
        company,
        photos,
        parameters: {
            productionYear,
        } = {},
        parameters
    } = car;
    const photoUrl = get(photos, "[0].url", null);
    const price = get(costs, "priceList[0].cost");

    return <div className={styles.carCard}>
        <div className={styles.mobileTop}>
            <CompanyLogo
                company={company}
                coverPhoto={coverPhoto}
                name={name}
            />
            {
                price && <PriceIntro
                    amount={price}
                    option="big"
                />
            }
        </div>
        <div className={styles.contentWrapper}>
            <div className={styles.leftColumn}>
                <img
                    className={styles.carPhoto}
                    src={photoUrl || "./static/cover.jpg"} alt="" />
            </div>


            <div className={styles.rightColumn}>
                <div className={styles.rightColumnHeader}>
                    <CompanyLogo
                        company={company}
                        coverPhoto={coverPhoto}
                        name={name}
                    />
                    {price &&
                        <PriceIntro
                            amount={price}
                            option="big"
                        />}
                </div>
                <div className={styles.rightColumnBody}>
                    <div className={styles.carDetails}>
                        <div className={styles.carTitle}>
                            {brandName} {model} {version}
                            <span className={styles.carTitleYOP}>
                                {productionYear}
                            </span>
                        </div>
                        <CarParamsList
                            parameters={parameters}
                            maxParams={7}
                        />
                    </div>
                    <div className={styles.btns}>
                        <Link href={url}>
                            <Button>
                                {t("common:btn.details")}
                            </Button>
                        </Link>
                        <SlideFrame
                            title={name}
                            Trigger={(props) => <Button
                                {...props}
                            >{t("common:contact")}</Button>}
                        >
                            <CarContactCard
                                company={company}
                            />
                        </SlideFrame>
                    </div>
                </div>
            </div>
        </div>
        <div className={styles.footer} />
    </div >;
};

type CompanyLogoProps = {
    coverPhoto?: string,
    name: string,
    company: CompanyI
}

const CompanyLogo = ({ coverPhoto, name, company }: CompanyLogoProps) => <Link href={getCompanySlug(company, true)}>
    {coverPhoto ? <img
        className={styles.companyLogo}
        src={coverPhoto}
        //todo alt pod seo
        alt="" /> : <div className={styles.companyName}>{name}</div>}
</Link>;


export default CarCard;