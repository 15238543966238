import { isFunction, isString } from "lodash";
import useTranslation from "next-translate/useTranslation";
import { IoCarSportOutline } from "react-icons/io5";

import styles from "../styles/CarCardSmall.module.scss";

const iconImage = (src: string) => <img
    src={src}
    className={styles.iconSvg}
    alt='' />;
// todo icons!
const icons = {
    horsePower: iconImage("/static/icons/turbo.svg"),
    acceleration: iconImage("/static/icons/clock.svg"),
    vmax: iconImage("/static/icons/performance.svg"),
    consumption: iconImage("/static/icons/oil.svg"),
    transmission: iconImage("/static/icons/gearbox.svg"),
    seats: iconImage("/static/icons/carSeat.svg"),
    drive: iconImage("/static/icons/carWheel.svg"),
    fuel: iconImage("/static/icons/gasStation.svg"),
    engine: iconImage("/static/icons/engine.svg"),
    body: IoCarSportOutline,
    color: iconImage("/static/icons/colorPalette.svg"),
};

const ParamsColumnIcon = ({
    // @ts-ignore
    param
}) => {
    const { t } = useTranslation();
    const name = Object.keys(param)[0];
    const value = Object.values(param)[0];
    // @ts-ignore
    const Icon = icons[name];

    return <div className={styles.paramsColumn}>
        {!isString(Icon) && <div className={styles.icon}>{
            isFunction(Icon) ? <Icon /> : Icon
        } </div>}
        <div className="">
            {t(`car:details.${name}`,
                { value },
                { fallback: t(`car:details.${name}.${value}`) }
            )}
        </div>
    </div>;
};



export default ParamsColumnIcon;