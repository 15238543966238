
import { CurrencyT, PriceListT } from "@/types";
import { Translate } from "next-translate";
import useTranslation from "next-translate/useTranslation";
import styles from "../styles/PriceBox.module.scss";

type Props = {
    currency: CurrencyT
} & Pick<PriceListT, "cost" | "rangeBottom" | "rangeTop">

type GetLabelsProps = {
    rangeBottom: number,
    rangeTop: number,
    cost: number,
    currency: CurrencyT,
    t: Translate
};

const getLabels = ({
    rangeBottom,
    rangeTop,
    t,
    cost,
    currency
}: GetLabelsProps) => {
    const isSameDay = rangeBottom === rangeTop;

    if (!isSameDay) return {
        timeLabel: t("common:money.rangeLabel", { rangeBottom, rangeTop }),
        priceLabel: t("common:money.moneyPerDay", {
            currency: t(`common:currency.${currency}`),
            cost
        })
    };

    return {
        timeLabel: t(`common:money.rangeLabels.${rangeBottom}`),
        priceLabel: t("common:money.moneyPerTime", {
            currency: t(`common:currency.${currency}`),
            cost
        })
    };
};

const PriceBox = ({
    cost,
    rangeBottom,
    rangeTop,
    currency,
}: Props) => {
    const { t } = useTranslation();
    const {
        timeLabel,
        priceLabel
    } = getLabels({ cost, currency, rangeBottom, rangeTop, t });
    return <div className={styles.priceBox}>
        <div className={styles.time}>
            {timeLabel}
        </div>
        <div className={styles.price}>
            {priceLabel}
        </div>
    </div>;
};

export default PriceBox;