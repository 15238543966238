import classNames from "classnames";
import useTranslation from "next-translate/useTranslation";
import { CurrencyT } from "src/types/Costs";
import styles from "../styles/PriceIntro.module.scss";

type Props = {
    amount: number,
    currency?: CurrencyT,
    time?: "DAY",
    option: "normal" | "big"
}
const PriceIntro = ({
    amount,
    currency = "PLN",
    time = "DAY",
    option = "normal"
}: Props) => {
    const { t } = useTranslation();
    const timeText = t(`common:time.${time}`);

    return <div className={classNames(styles.priceIntroWrapper, styles[option])}>
        <div className={styles.priceFrom}>{t("common:money.priceFrom")}</div>
        <div className={styles.priceAmount}>{amount}{t(`common:currency.${currency}`)}</div>
        <div className={styles.priceTimeUnit}>{`/${timeText}`}</div>
    </div>;
};


export default PriceIntro;