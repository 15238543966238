import { Contact, Socials } from "@/c/Layout";
import { CompanyGeoAddressI, CompanyI } from "@/types";
import { getStreetAddress } from "@/utils/getters";

import styles from "../styles/CarContactCard.module.scss";

type Props = {
    company: CompanyI
}

const displayAddress = (address: CompanyGeoAddressI) => {
    if (address) {
        const displayStreet = getStreetAddress(address);

        return `${address?.city}${displayStreet && ","} ${displayStreet}`;
    }

    return "";
};

const CarContactCard = ({
    company
}: Props) => {
    const {
        coverPhoto,
        address,
        contact: {
            urls
        },
        contact
    } = company;
    const addressText = displayAddress(address);

    return <div className={styles.carContactCard}>
        {coverPhoto &&
            <div className={styles.logoWrapper}>
                <img
                    className={styles.logo}
                    src={coverPhoto}
                    alt=""
                />
            </div>
        }
        {
            addressText && <p className={styles.address}>
                {addressText}
            </p>
        }
        <Contact
            contact={contact}
            type="LIST"
        />
        <Socials
            list={urls}
            type="LIST" />

    </div>;
};

export default CarContactCard;