import { Section, Header } from "@/c/Layout";
import PriceBox from "./PriceBox";

import styles from "../styles/CarCosts.module.scss";
import useTranslation from "next-translate/useTranslation";
import { CostsT } from "@/types";

type Props = {
    costs: CostsT
}

const CarCosts = ({ costs }: Props) => {
    const {
        priceList,
        currency,
        additional: {
            bailCost,
            distanceLimit,
            priceAboveLimit
        }
    } = costs;
    const { t } = useTranslation();

    return <Section
        withPaddingsY
    >
        <Header
            variant="h2"
            isBold
            spaceBottom
        >
            {t("common:money.rates")}
        </Header>
        {priceList && <div className={styles.priceListWrapper}>
            {
                priceList.map(({ cost, rangeBottom, rangeTop }) => <PriceBox
                    key={cost}
                    cost={cost}
                    rangeBottom={rangeBottom}
                    rangeTop={rangeTop}
                    currency={currency}
                />)
            }
        </div>}
        <br />
        {
            (bailCost || distanceLimit || priceAboveLimit) &&
            <div className={styles.priceListWrapper}>
                {bailCost && <span>{t("common:money.bailCost", { bailCost, currency: t(`common:currency.${currency}`) })}</span>}
                {distanceLimit && <span>{t("common:money.distanceLimit", { distanceLimit })}</span>}
                {priceAboveLimit && <span>{t("common:money.priceAboveLimit", { priceAboveLimit, currency: t(`common:currency.${currency}`) })}</span>}
            </div>
        }
    </Section>;
};

export default CarCosts;